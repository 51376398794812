import React from 'react'
import { graphql } from 'gatsby'
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import Button from '../components/button'
import AnimatedArrow from '../components/animated-arrow'

// Assets
import Pattern from '../images/title-pattern.inline.svg'

// Styles
import '../styles/pages/contact.scss'

// const HeroSection = () => {
//   const { t } = useTranslation()
//   return (
//     <section className="hero-section flex items-end px-6 pb-7 md:pb-16 md:px-20 lg:px-32 ">
//       <div className="2xl:max-w-screen-xl 2xl:mx-auto w-full">
//         <h1 className="text-tuya-dark">
//           <Trans>Contact us</Trans>
//         </h1>
//       </div>
//     </section>
//   )
// }

const MainSection = ({ data, info }) => {
  const { t } = useTranslation()
  function cleanPhone(phone) {
    return phone.replace(/[\W_]+/g, '')
  }
  return (
    <section className="text-tuya-zeta main-section px-6 py-8 pt-36 md:p-20 md:pr-1 md:pt-48 md:pb-0 lg:p-32 lg:pb-0 md:-mb-36 lg:mb-0 2xl:max-w-screen-xl 2xl:mx-auto 2xl:px-0">
      <div className="content-wrapper">
        <h1 className="text-4xl py-10">
          <Trans>Contact us</Trans>
        </h1>
        <p className="font-light mb-10">{data.mainText}</p>
        <div>
          <h6 className="mb-4 text-tuya-iota">
            For reservations or any inquiry, information or suggestion, please contact us at:
          </h6>
          <p className="text-xl mb-16 leading-7">
            Phone: <a href={`tel:+${cleanPhone(info.phone)}`}>{info.phone}</a>
            <br />
            E-mail:{' '}
            <a className="font-semibold" href={`mailto:${info.email}`}>
              {info.email}
            </a>
            <br />
            {info.address}
          </p>
        </div>
        <div className="mb-16">
          <a href="/reservation">
            <Button isDark>RESERVATION</Button>
          </a>
        </div>
      </div>
      <div className="img-wrapper mb-10 md:mb-0 lg:pt-10">
        <div className="opening-hours mb-4 hidden lg:block">
          <h6 className="text-tuya-iota mb-1 small-text-spaced">OPENING HOURS</h6>
          <p>
            {info.normalStartDay}-{info.normalEndDay}: {info.normalInterval}
          </p>
          {/* <p>
            {info.weekendStartDay === info.weekendEndDay ? (
              <>
                {info.weekendStartDay}: {info.weekendInterval}
              </>
            ) : (
              <>
                {info.weekendStartDay}-{info.weekendEndDay}: {info.weekendInterval}
              </>
            )}
          </p> */}
          {/* <p>{info.kitchenClose}</p>
        <p>{info.barClose}</p> */}
        </div>
        <img
          className="object-cover w-full h-full"
          src={data.image.sizes.src}
          srcSet={data.image.sizes.srcSet}
          alt="Tuya Resturant"
        />
      </div>
      {/* <div className="p-7 md:px-16 bg-grey-200 jobs-wrapper md:relative md:-top-20 lg:static">
        <Pattern className="mb-6" />
        <p style={{ lineHeight: 1.67, color: '#646464' }} className="font-light text-lg mb-6">
          Blending flavors. Friendly team.
          <br />
          Don’t miss the opportunities
        </p>
        <Link style={{ fontSize: '1.75rem' }} className="text-tuya-slate-light" to="/jobs">
          Jobs
          <AnimatedArrow className="h-3 text-tuya-dark" />
        </Link>
      </div> */}
    </section>
  )
}

const FoodPage = ({ data: graphqlData, location }) => {
  const data = graphqlData.datoCmsContact
  const info = graphqlData.datoCmsResturantInformation
  return (
    <Layout className="contact-page" pathname={location.pathname} alwaysBlack>
      <SEO title="Contact" />
      {/* <HeroSection /> */}
      <MainSection data={data} info={info} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "contact"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    datoCmsContact {
      image {
        sizes {
          src
          srcSet
        }
      }
      mainText
    }
    datoCmsResturantInformation {
      phone
      email
      address
      normalStartDay
      normalEndDay
      normalInterval
      weekendStartDay
      weekendEndDay
      weekendInterval
      kitchenClose
      barClose
    }
  }
`

export default FoodPage
